import {
  RDDairySoilsEnrollment,
  RDEnrolledOtherCarbon,
  RDEqipApplicationResults,
  RDEqipCurrentPractices,
  RDEqipEligibilityConservationACEP,
  RDEqipEligibilityConservationCRP,
  RDEqipEligibilityConservationCSP,
  RDEqipEligibilityFarmInfo,
  RDEqipEligibilityLandControl,
  RDEqipEligibilityWetlandCompliance,
  RDEqipFieldsSelection,
  RDEqipFsaForms,
  RDEqipHasFarmInfo,
  RDEqipOptionalForms,
  RDEqipPracticeChoiceAgroforestry,
  RDEqipPracticeChoiceEngineeringLandErosionStructures,
  RDEqipPracticeChoiceEngineeringWaterControlStructures,
  RDEqipPracticeChoiceSoil,
  RDEqipPracticeChoiceWildlife,
  RDEqipPracticeSelectionConfirmation,
  RDEqipPracticesFlooding,
  RDEqipPracticesIrrigationMethod,
  RDEqipPracticesIrrigationQuantity,
  RDEqipPracticesNaturalMoisture,
  RDEqipPracticesSeeps,
  RDEqipPracticesSnowDrifts,
  RDEqipPracticesSoilCompaction,
  RDEqipPracticesSoilCover,
  RDEqipPracticesSoilErosionClassicalGully,
  RDEqipPracticesSoilErosionEphemeralGully,
  RDEqipPracticesSoilSalinity,
  RDEqipPracticesSoilSubsidence,
  RDEqipPracticesWaterTable,
  RDEqipRecommendedPracticesIntent,
  RDEqipRequiredForms,
  RDEqipReviewRecommendationResults,
  RDLolFarmId,
  RDProductAppliedPerPlan,
  RDUsdaAgiThreshold,
  RDUsdaEarlyAdopter,
  RDUsdaFederalProgramEnrollment,
  RDUsdaGrowerType,
  RDUsdaUnderservedSmall,
  TraitId,
} from '@cibo/core'
import {
  CropManagementDataResourceDetailFeature,
  DairySoilsEnrollment,
  EarlyAdopterTillageFeature,
  EnrolledOtherCarbon,
  PLUIdDetailFeature,
  ProductAppliedPerPlan,
  RDCottonNitrogenReductionResourceDetailFeature,
  RDEarlyAdopterTillage,
  RDEqipEligibilityConservationACEPResourceDetailFeature,
  RDEqipEligibilityConservationCRPResourceDetailFeature,
  RDEqipEligibilityConservationCSPResourceDetailFeature,
  RDEqipEligibilityFarmInfoDetailFeature,
  RDEqipEligibilityLandControlResourceDetailFeature,
  RDEqipEligibilityWetlandComplianceResourceDetailFeature,
  RDEqipPracticesFloodingResourceDetailFeature,
  RDEqipPracticesIrrigationMethodResourceDetailFeature,
  RDEqipPracticesIrrigationQuantityResourceDetailFeature,
  RDEqipPracticesNaturalMoistureResourceDetailFeature,
  RDEqipPracticesSeepsResourceDetailFeature,
  RDEqipPracticesSnowDriftsResourceDetailFeature,
  RDEqipPracticesSoilCompactionResourceDetailFeature,
  RDEqipPracticesSoilCoverDetailFeature,
  RDEqipPracticesSoilErosionClassicalGullyResourceDetailFeature,
  RDEqipPracticesSoilErosionEphemeralGullyResourceDetailFeature,
  RDEqipPracticesSoilSalinityResourceDetailFeature,
  RDEqipPracticesSoilSubsidenceResourceDetailFeature,
  RDEqipPracticesWaterTableDetailFeature,
  RDOnFarmDairyFeed,
  RDOnFarmDairyFeedFeature,
  RDUsdaFederalProgramEnrollmentResourceDetailFeature,
} from '@cibo/landmanager'
import { RDCottonNitrogenReduction } from '@cibo/landmanager/src/features/ResourceDetailFeature/CottonNitrogenReduction/types'
import { RDCropManagementData } from '@cibo/landmanager/src/features/ResourceDetailFeature/CropManagementData/types'
import { RDEqipPLUId } from '@cibo/landmanager/src/features/ResourceDetailFeature/EQIP/PLUId/types'
import {
  RDLolFarmIdResourceDetailFeature,
  RDUsdaAgiThresholdResourceDetailFeature,
  RDUsdaGrowerTypeResourceDetailFeature,
  RDUsdaUnderservedSmallResourceDetailFeature,
  UsdaEarlyAdopter,
  UsdaGrowerDetailsFeatureMap,
} from '@cibo/profile'
import { UsdaGrowerDetails } from '@cibo/profile/src/features/ResourceDetailFeatures/EQIP/UsdaGrowerDetails/types'
import { ResourceDetailFeature } from '@cibo/ui'
import {
  EqipFarmInformationDetailFeatureMap,
  RDEqipAutogenRequiredForms,
  RDEqipAutogenRequiredFormsFeatureMap,
  RDEqipCurrentPracticesDetailFeature,
  RDEqipDocuments,
  RDEqipDocumentsFeatureMap,
  RDEqipFieldsSelectionFeature,
  RDEqipFsaFormsResourceDetailFeature,
  RDEqipHasFarmInfoResourceDetailFeature,
  RDEqipOptionalFormsResourceDetailFeature,
  RDEqipPracticeChoiceAgroforestryResourceDetailFeature,
  RDEqipPracticeChoiceEngineeringLandErosionStructuresResourceDetailFeature,
  RDEqipPracticeChoiceEngineeringWaterControlStructuresResourceDetailFeature,
  RDEqipPracticeChoiceSoilResourceDetailFeature,
  RDEqipPracticeChoiceWildlifeResourceDetailFeature,
  RDEqipPracticeSelectionConfirmationFeature,
  RDEqipProofOfLandControl,
  RDEqipProofOfLandControlFeatureMap,
  RDEqipRecommendedPracticesIntentResourceDetailFeature,
  RDEqipRequiredFormsResourceDetailFeature,
  RDEqipReviewRecommendationResultsResourceDetailFeature,
  TraitTableTaskEditor,
} from '../../../../features/ResourceDetailFeatures'
import { EqipFarmInformation } from '../../../../features/ResourceDetailFeatures/EQIP/EqipFarmInformation/types'
import { RDEqipApplicationResultsResourceDetailFeature } from '../../../../features/ResourceDetailFeatures/EQIP/RDEqipApplicationResultsResourceDetailFeature'

const LegacyTraitTableFeatures = [
  'agronomicDataCsv',
  'amendment',
  'amendment',
  'amendmentSummary',
  'biological',
  'biomassBurning',
  'cashCrop',
  'cornNitrogenApplication',
  'coverCrop',
  'eventIrrigation',
  'farmManagementSystem',
  'fertilizer',
  'fertilizerReduction',
  'futureCashCrop',
  'futureCoverCrop',
  'futureLivestockChange',
  'futureTillage',
  'grazing',
  'includesWetlandDesignation',
  'irrigation',
  'liming',
  'livestock',
  'nativeVegetationConversion',
  'nativeVegetationConversionDate',
  'naturalDisturbance',
  'ownership',
  'shipmentDistance',
  'tillage',
  'waterPumpIrrigation',
].reduce(
  (acc, traitId) => ({
    ...acc,
    [traitId]: {
      Component: {
        traitId,
        TaskEditor: TraitTableTaskEditor,
      },
      resourceType: 'field',
    },
  }),
  {}
)

export const ResourceDetailFeatureMap: Partial<
  Record<
    TraitId,
    {
      Component:
        | ResourceDetailFeature<RDCropManagementData>
        | ResourceDetailFeature<RDCottonNitrogenReduction>
        | ResourceDetailFeature<EqipFarmInformation>
        | ResourceDetailFeature<RDDairySoilsEnrollment>
        | ResourceDetailFeature<RDEnrolledOtherCarbon>
        | ResourceDetailFeature<RDEqipApplicationResults>
        | ResourceDetailFeature<RDEqipAutogenRequiredForms>
        | ResourceDetailFeature<RDEqipCurrentPractices>
        | ResourceDetailFeature<RDEqipDocuments, { docusignType: string }>
        | ResourceDetailFeature<RDEqipEligibilityConservationACEP>
        | ResourceDetailFeature<RDEqipEligibilityConservationCRP>
        | ResourceDetailFeature<RDEqipEligibilityConservationCSP>
        | ResourceDetailFeature<RDEqipEligibilityFarmInfo>
        | ResourceDetailFeature<RDEqipEligibilityLandControl>
        | ResourceDetailFeature<RDEqipEligibilityWetlandCompliance>
        | ResourceDetailFeature<RDEqipFieldsSelection>
        | ResourceDetailFeature<RDEqipFieldsSelection>
        | ResourceDetailFeature<RDEqipFsaForms>
        | ResourceDetailFeature<RDEqipHasFarmInfo>
        | ResourceDetailFeature<RDEqipOptionalForms>
        | ResourceDetailFeature<RDEqipPLUId>
        | ResourceDetailFeature<RDEqipPracticeChoiceAgroforestry>
        | ResourceDetailFeature<RDEqipPracticeChoiceEngineeringLandErosionStructures>
        | ResourceDetailFeature<RDEqipPracticeChoiceEngineeringWaterControlStructures>
        | ResourceDetailFeature<RDEqipPracticeChoiceSoil>
        | ResourceDetailFeature<RDEqipPracticeChoiceWildlife>
        | ResourceDetailFeature<RDEqipPracticeSelectionConfirmation>
        | ResourceDetailFeature<RDEqipPracticesFlooding>
        | ResourceDetailFeature<RDEqipPracticesIrrigationMethod>
        | ResourceDetailFeature<RDEqipPracticesIrrigationQuantity>
        | ResourceDetailFeature<RDEqipPracticesNaturalMoisture>
        | ResourceDetailFeature<RDEqipPracticesSeeps>
        | ResourceDetailFeature<RDEqipPracticesSnowDrifts>
        | ResourceDetailFeature<RDEqipPracticesSoilCompaction>
        | ResourceDetailFeature<RDEqipPracticesSoilCover>
        | ResourceDetailFeature<RDEqipPracticesSoilErosionClassicalGully>
        | ResourceDetailFeature<RDEqipPracticesSoilErosionEphemeralGully>
        | ResourceDetailFeature<RDEqipPracticesSoilSalinity>
        | ResourceDetailFeature<RDEqipPracticesSoilSubsidence>
        | ResourceDetailFeature<RDEqipPracticesWaterTable>
        | ResourceDetailFeature<RDEqipProofOfLandControl>
        | ResourceDetailFeature<RDEqipRecommendedPracticesIntent>
        | ResourceDetailFeature<RDEqipRequiredForms>
        | ResourceDetailFeature<RDEqipReviewRecommendationResults>
        | ResourceDetailFeature<RDLolFarmId>
        | ResourceDetailFeature<RDOnFarmDairyFeed>
        | ResourceDetailFeature<RDProductAppliedPerPlan>
        | ResourceDetailFeature<RDUsdaAgiThreshold>
        | ResourceDetailFeature<RDUsdaEarlyAdopter>
        | ResourceDetailFeature<RDUsdaFederalProgramEnrollment>
        | ResourceDetailFeature<RDUsdaGrowerType>
        | ResourceDetailFeature<RDUsdaUnderservedSmall>
        | ResourceDetailFeature<UsdaGrowerDetails>
        | ResourceDetailFeature<RDEarlyAdopterTillage>
      resourceType: 'field' | 'user' | 'workflow'
    }
  >
> = {
  cottonNitrogenReduction: {
    Component: RDCottonNitrogenReductionResourceDetailFeature,
    resourceType: 'field',
  },
  // @deprecated
  dairySoilsEnrollment: {
    Component: DairySoilsEnrollment,
    resourceType: 'field',
  },
  earlyAdopterTillage: {
    Component: EarlyAdopterTillageFeature,
    resourceType: 'field',
  },
  enrolledOtherCarbon: {
    Component: EnrolledOtherCarbon,
    resourceType: 'field',
  },
  cropManagementData: {
    Component: CropManagementDataResourceDetailFeature,
    resourceType: 'field',
  },
  eqipApplicationResults: {
    Component: RDEqipApplicationResultsResourceDetailFeature,
    resourceType: 'workflow',
  },
  eqipPLUId: {
    Component: PLUIdDetailFeature,
    resourceType: 'field',
  },
  eqipCurrentPractices: {
    Component: RDEqipCurrentPracticesDetailFeature,
    resourceType: 'field',
  },
  eqipEligibilityFarmInfo: {
    Component: RDEqipEligibilityFarmInfoDetailFeature,
    resourceType: 'field',
  },
  eqipEligibilityLandControl: {
    Component: RDEqipEligibilityLandControlResourceDetailFeature,
    resourceType: 'field',
  },
  eqipEligibilityConservationACEP: {
    Component: RDEqipEligibilityConservationACEPResourceDetailFeature,
    resourceType: 'field',
  },
  eqipEligibilityConservationCRP: {
    Component: RDEqipEligibilityConservationCRPResourceDetailFeature,
    resourceType: 'field',
  },
  eqipEligibilityConservationCSP: {
    Component: RDEqipEligibilityConservationCSPResourceDetailFeature,
    resourceType: 'field',
  },
  eqipEligibilityWetlandCompliance: {
    Component: RDEqipEligibilityWetlandComplianceResourceDetailFeature,
    resourceType: 'field',
  },
  eqipPracticesIrrigationMethod: {
    Component: RDEqipPracticesIrrigationMethodResourceDetailFeature,
    resourceType: 'field',
  },
  eqipPracticesSeeps: {
    Component: RDEqipPracticesSeepsResourceDetailFeature,
    resourceType: 'field',
  },
  eqipPracticesSnowDrifts: {
    Component: RDEqipPracticesSnowDriftsResourceDetailFeature,
    resourceType: 'field',
  },
  eqipPracticesSoilCompaction: {
    Component: RDEqipPracticesSoilCompactionResourceDetailFeature,
    resourceType: 'field',
  },
  eqipPracticesSoilCover: {
    Component: RDEqipPracticesSoilCoverDetailFeature,
    resourceType: 'field',
  },
  eqipPracticesSoilErosionClassicalGully: {
    Component: RDEqipPracticesSoilErosionClassicalGullyResourceDetailFeature,
    resourceType: 'field',
  },
  eqipPracticesSoilErosionEphemeralGully: {
    Component: RDEqipPracticesSoilErosionEphemeralGullyResourceDetailFeature,
    resourceType: 'field',
  },
  eqipPracticesSoilSubsidence: {
    Component: RDEqipPracticesSoilSubsidenceResourceDetailFeature,
    resourceType: 'field',
  },
  eqipPracticesSoilSalinity: {
    Component: RDEqipPracticesSoilSalinityResourceDetailFeature,
    resourceType: 'field',
  },
  eqipPracticesFlooding: {
    Component: RDEqipPracticesFloodingResourceDetailFeature,
    resourceType: 'field',
  },
  eqipPracticesWaterTable: {
    Component: RDEqipPracticesWaterTableDetailFeature,
    resourceType: 'field',
  },
  eqipFarmInformation: EqipFarmInformationDetailFeatureMap,
  eqipHasFarmInfo: {
    Component: RDEqipHasFarmInfoResourceDetailFeature,
    resourceType: 'workflow',
  },
  eqipPracticeChoiceAgroforestry: {
    Component: RDEqipPracticeChoiceAgroforestryResourceDetailFeature,
    resourceType: 'workflow',
  },
  eqipPracticeChoiceEngineeringLandErosionStructures: {
    Component: RDEqipPracticeChoiceEngineeringLandErosionStructuresResourceDetailFeature,
    resourceType: 'workflow',
  },
  eqipPracticeChoiceEngineeringWaterControlStructures: {
    Component: RDEqipPracticeChoiceEngineeringWaterControlStructuresResourceDetailFeature,
    resourceType: 'workflow',
  },
  eqipPracticeChoiceSoil: {
    Component: RDEqipPracticeChoiceSoilResourceDetailFeature,
    resourceType: 'workflow',
  },
  eqipPracticeChoiceWildlife: {
    Component: RDEqipPracticeChoiceWildlifeResourceDetailFeature,
    resourceType: 'workflow',
  },
  eqipPracticesIrrigationQuantity: {
    Component: RDEqipPracticesIrrigationQuantityResourceDetailFeature,
    resourceType: 'field',
  },
  eqipPracticesNaturalMoisture: {
    Component: RDEqipPracticesNaturalMoistureResourceDetailFeature,
    resourceType: 'field',
  },
  eqipRecommendedPracticesIntent: {
    Component: RDEqipRecommendedPracticesIntentResourceDetailFeature,
    resourceType: 'workflow',
  },
  practiceSelectionIntentFromPoints: {
    Component: RDEqipRecommendedPracticesIntentResourceDetailFeature,
    resourceType: 'workflow',
  },
  eqipReviewRecommendationResults: {
    Component: RDEqipReviewRecommendationResultsResourceDetailFeature,
    resourceType: 'workflow',
  },
  eqipSelectedPractices: {
    Component: RDEqipFieldsSelectionFeature,
    resourceType: 'field',
  },
  eqipPracticeSelectionConfirmation: {
    Component: RDEqipPracticeSelectionConfirmationFeature,
    resourceType: 'workflow',
  },
  eqipRequiredForms: {
    Component: RDEqipRequiredFormsResourceDetailFeature,
    resourceType: 'workflow',
  },
  eqipFsaForms: {
    Component: RDEqipFsaFormsResourceDetailFeature,
    resourceType: 'workflow',
  },
  eqipOptionalForms: {
    Component: RDEqipOptionalFormsResourceDetailFeature,
    resourceType: 'workflow',
  },
  eqipAutogenRequiredForms: RDEqipAutogenRequiredFormsFeatureMap,
  // @ts-ignore Not sure how to make tsc happy here
  eqipDocuments: RDEqipDocumentsFeatureMap,
  lolFarmId: {
    Component: RDLolFarmIdResourceDetailFeature,
    resourceType: 'user',
  },
  onFarmDairyFeed: {
    Component: RDOnFarmDairyFeedFeature,
    resourceType: 'field',
  },
  proofOfLandControl: RDEqipProofOfLandControlFeatureMap,
  productAppliedPerPlan: {
    Component: ProductAppliedPerPlan,
    resourceType: 'field',
  },
  usdaAgiThreshold: {
    Component: RDUsdaAgiThresholdResourceDetailFeature,
    resourceType: 'user',
  },
  usdaEarlyAdopter: {
    Component: UsdaEarlyAdopter,
    resourceType: 'user',
  },
  usdaFederalProgramEnrollment: {
    Component: RDUsdaFederalProgramEnrollmentResourceDetailFeature,
    resourceType: 'field',
  },
  usdaGrowerDetails: UsdaGrowerDetailsFeatureMap,
  usdaGrowerType: {
    Component: RDUsdaGrowerTypeResourceDetailFeature,
    resourceType: 'user',
  },
  usdaUnderservedSmall: {
    Component: RDUsdaUnderservedSmallResourceDetailFeature,
    resourceType: 'user',
  },

  ...LegacyTraitTableFeatures,
}

export const resourceDetailFeatureByTraitId = (traitId?: TraitId) => {
  return traitId ? ResourceDetailFeatureMap[traitId] : undefined
}
