import {
  FERTILIZER_GALLONS_PER_ACRE,
  FERTILIZER_METHODS,
  FERTILIZER_TYPE,
  FERTILIZER_TYPE_LB_PER_ACRE,
  FERTILIZER_TYPE_RATE_UNIT,
  FERTILIZER_WEIGHT_PER_ACRE,
} from '@cibo/core'
import { filter, sum } from 'ramda'
import * as Yup from 'yup'
import { ValidationSchemaFunctionArguments } from '../types'
import { isDetailRequired } from '../useIsRequired'

const constituentValidation = ({
  fieldName,
  requirement,
  t,
}: ValidationSchemaFunctionArguments & { fieldName: string }) => {
  const stringSchema = Yup.string()
  const numberSchema = Yup.number()
    .min(0, t('min', { contet: 'percent' }))
    .max(100, t('max', { context: 'percent' }))
    .typeError(t('required', { context: 'percent' }))

  return Yup.lazy(value =>
    isDetailRequired({ requirement, fieldName })
      ? value === ''
        ? stringSchema.required(t('required', { context: value }))
        : numberSchema.required(t('required', { context: value }))
      : value === ''
      ? stringSchema.nullable()
      : numberSchema.nullable()
  )
}

export const FertilizerEventValidationSchema = ({
  t,
  requirement,
}: ValidationSchemaFunctionArguments) =>
  Yup.object().shape({
    date: Yup.date()
      .required(t('required', { context: 'date' }))
      .typeError(t('required', { context: 'date' })),
    type: Yup.string()
      .oneOf(FERTILIZER_TYPE, t('oneOf', { context: 'type' }))
      .required(t('required', { context: 'type' })),
    units: Yup.mixed().test('amountPresent', t('amountNotPresent'), function () {
      const { type, nitrogenAmnt, units } = this.parent
      if (nitrogenAmnt !== undefined && nitrogenAmnt > 0) {
        return true
      }
      if (FERTILIZER_TYPE_RATE_UNIT.includes(type)) {
        if (FERTILIZER_GALLONS_PER_ACRE.includes(type)) {
          return units === 'gallonsPerAcre'
        }
        if (FERTILIZER_TYPE_LB_PER_ACRE.includes(type) || type === 'other') {
          return true
        }
        if (FERTILIZER_WEIGHT_PER_ACRE.includes(type)) {
          return ['poundsPerAcre', 'ouncesPerAcre'].includes(units)
        }
      }
      return false
    }),
    rate: Yup.number()
      .min(1, t('min', { context: 'rate' }))
      .test('rate', t('required', { context: 'rate' }), function () {
        const { type, nitrogenAmnt, rate } = this.parent
        if (FERTILIZER_TYPE_RATE_UNIT.includes(type)) {
          return (
            (rate !== undefined && rate > 0) || (nitrogenAmnt !== undefined && nitrogenAmnt > 0)
          )
        } else {
          return nitrogenAmnt !== undefined && nitrogenAmnt > 0
        }
      })
      .when('units', {
        is: 'poundsPerAcre',
        then: Yup.number().max(400, t('max', { context: 'rate', max: 400 })),
        otherwise: Yup.number().when('type', {
          is: 'uan28',
          then: Yup.number().max(137, t('max', { context: 'rate', max: 137 })),
          otherwise: Yup.number().when('type', {
            is: 'uan30',
            then: Yup.number().max(125, t('max', { context: 'rate', max: 125 })),
            otherwise: Yup.number().when('type', {
              is: 'uan32',
              then: Yup.number().max(114, t('max', { context: 'rate', max: 114 })),
              otherwise: Yup.number().max(1000, t('max', { context: 'rate', max: 1000 })),
            }),
          }),
        }),
      }),
    nitrogenAmnt: Yup.number()
      .min(0)
      .max(400)
      .test('nitrogenAmnt', t('required', { context: 'nitrogenAmnt' }), function () {
        const { type, nitrogenAmnt, rate, units } = this.parent
        if (FERTILIZER_TYPE_RATE_UNIT.includes(type)) {
          return (!!rate && !!units) || (nitrogenAmnt !== undefined && nitrogenAmnt > 0)
        } else {
          return nitrogenAmnt !== undefined && nitrogenAmnt > 0
        }
      }),
    method: Yup.string()
      .oneOf(FERTILIZER_METHODS, t('oneOf', { context: 'method' }))
      [isDetailRequired({ requirement, fieldName: 'method' }) ? 'required' : 'nullable'](
        t('required', { context: 'method' })
      ),
    slowRelease: Yup.boolean()
      .typeError(t('required', { context: 'slowRelease' }))
      [isDetailRequired({ requirement, fieldName: 'slowRelease' }) ? 'required' : 'nullable'](
        t('required', { context: 'slowRelease' })
      ),
    inhibitor: Yup.boolean()
      .typeError(t('required', { context: 'inhibitor' }))
      [isDetailRequired({ requirement, fieldName: 'inhibitor' }) ? 'required' : 'nullable'](
        t('required', { context: 'inhibitor' })
      ),
    ammonium: Yup.boolean()
      .typeError(t('required', { context: 'ammonium' }))
      [isDetailRequired({ requirement, fieldName: 'ammonium' }) ? 'required' : 'nullable'](
        t('required', { context: 'ammonium' })
      ),
    nitrogenPer: constituentValidation({ t, requirement, fieldName: 'nitrogenPer' }),
    phosphorusPer: constituentValidation({ t, requirement, fieldName: 'phosphorusPer' }),
    potassiumPer: constituentValidation({ t, requirement, fieldName: 'potassiumPer' }),
    sulfurPer: constituentValidation({ t, requirement, fieldName: 'sulfurPer' }),
    combinedPercent: Yup.mixed().test(
      'componentMax',
      t('max', { context: 'combined' }),
      function () {
        const { sulfurPer, potassiumPer, phosphorusPer, nitrogenPer } = this.parent
        return sum(filter(a => !!a, [sulfurPer, potassiumPer, phosphorusPer, nitrogenPer])) <= 100
      }
    ),
    product: Yup.string().when('type', {
      is: [...FERTILIZER_TYPE_LB_PER_ACRE, 'other'],
      then: Yup.string().required(t('required', { context: 'product' })),
      otherwise: Yup.string()[
        isDetailRequired({ requirement, fieldName: 'product' }) ? 'required' : 'nullable'
      ](t('required', { context: 'product' })),
    }),
  })
