import { ResourceDetail, ResourceDetailsUpdate } from '@cibo/core'
import { DataGridPro, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRenderCellParams,
  GridRowHeightReturnValue,
  GridRowId,
} from '@mui/x-data-grid-pro'
import { assocPath, pathEq, propEq } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFieldColumns } from '../../../components/FieldColumns/useFieldColumns'
import { useFields, useUpdateMultipleFields } from '../../../queries'
import { BooleanDetailCheckboxCellRenderer } from './BooleanDetailCheckboxCellRenderer'

const BULK_ANSWERS = ['yes', 'no', 'unknown'] as const
export type BulkAnswer = typeof BULK_ANSWERS[number]

export type BooleanDetailSelectionTableProps<T extends ResourceDetail = ResourceDetail> = {
  detailKey?: keyof T['input'] | keyof T['result']
  answerValues?: Partial<Record<BulkAnswer, { value: any }>>
  rowSelectedAnswer?: 'yes' | 'no'
} & ResourceDetailFeatureTaskEditorProps<T>

export const BooleanDetailSelectionTable = <T extends ResourceDetail = ResourceDetail>({
  answerValues = { yes: { value: 'yes' }, no: { value: 'no' } },
  detailKey,
  rowSelectedAnswer = 'yes',
  detailRequirements: [{ traitId, year, resultsOnly }],
  onError,
  onSuccess,
  onUpdating,
  resourceIds,
}: BooleanDetailSelectionTableProps<T>) => {
  const updateFields = useUpdateMultipleFields()
  const fieldModels = useFields(resourceIds)
  const [rowSelectionModel, setRowSelectionModel] = useState<string[]>()

  const { fieldNameColumn } = useFieldColumns()

  const columns = useMemo(() => {
    return [
      /*
       * Override the selection checkbox renderer to disable the checkbox when the detail
       * is immutable. As far as DataGrid is concerned, the row is still "selectable" so the
       * bulk checkbox could change it's selected status. Here, we control the rowSelectionModel
       * and weed out any selection changes for immutable rows.
       */
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        renderCell: (props: GridRenderCellParams) => (
          <BooleanDetailCheckboxCellRenderer {...props} traitId={traitId} />
        ),
      },
      fieldNameColumn,
    ]
  }, [])

  const rowDeselectedAnswer: BulkAnswer = rowSelectedAnswer === 'yes' ? 'no' : 'yes'

  const valuePath = useMemo(() => {
    const valuePath: Array<string> = [resultsOnly ? 'result' : 'input']
    if (detailKey) valuePath.push(detailKey as string)
    return valuePath
  }, [detailKey, resultsOnly])

  const detailHasAnswer = useCallback(
    (answer: BulkAnswer) => pathEq(valuePath, answerValues[answer]?.value),
    [valuePath, answerValues]
  )

  // init selections from saved details
  useEffect(() => {
    if (!fieldModels.isFetched) {
      return
    }

    const newSelectionModel = Array<string>()

    fieldModels.data?.forEach(fieldModel => {
      const detail = fieldModel.resolveStandingDetail(traitId, year)

      if (detail && detailHasAnswer(rowSelectedAnswer)(detail)) {
        newSelectionModel.push(fieldModel.resourceId)
      }
    })

    setRowSelectionModel(newSelectionModel)
  }, [fieldModels.isFetched])

  const syncAnswers = (answers: Array<{ resourceId: string; answer: BulkAnswer }>) => {
    const template = { traitId, year }

    const updates: ResourceDetailsUpdate[] = []

    answers.forEach(({ resourceId, answer }) => {
      const fieldModel = fieldModels.data?.find(propEq('resourceId', resourceId))
      if (!fieldModel) return

      const detail = fieldModel?.resolveStandingDetail(traitId, year)

      if (detail?.immutable) return

      if (!detailHasAnswer(answer)(detail)) {
        updates.push({
          resourceId: fieldModel.resourceId,
          details: [assocPath(valuePath, answerValues?.[answer]?.value, template)],
        })
      }
    })

    if (updates.length === 0) return

    onUpdating?.()
    updateFields
      .mutateAsync(updates)
      .then(() => {
        fieldModels.refetch().then(onSuccess)
      })
      .catch(onError)
  }

  const onRowSelectionModelChange = (newSelectionModel: GridRowId[]) => {
    const selectedRows = new Set<string>(newSelectionModel as string[])

    const answers: Array<{ resourceId: string; answer: BulkAnswer }> = []

    fieldModels.data?.forEach(fieldModel => {
      const detail = fieldModel.resolveStandingDetail(traitId, year)

      if (detail?.immutable) {
        // omit requested changes on immutable rows
        if (detailHasAnswer(rowSelectedAnswer)(detail)) {
          selectedRows.add(fieldModel.resourceId)
        } else {
          selectedRows.delete(fieldModel.resourceId)
        }
      } else {
        answers.push({
          resourceId: fieldModel.resourceId,
          answer: newSelectionModel.includes(fieldModel.resourceId)
            ? rowSelectedAnswer
            : rowDeselectedAnswer,
        })
      }
    })

    setRowSelectionModel(Array.from(selectedRows))

    syncAnswers(answers)
  }

  const getRowHeight = useCallback((): GridRowHeightReturnValue => 'auto', [])

  return (
    <DataGridPro
      columns={columns}
      loading={fieldModels.isPending || updateFields.isPending}
      rows={fieldModels.data ?? []}
      checkboxSelection
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={onRowSelectionModelChange}
      getRowHeight={getRowHeight}
      autoHeight
      hideCellFocus
    />
  )
}
