import { useAuth } from '@cibo/profile'
import { Markdown } from '@cibo/ui'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import HelpIcon from '@mui/icons-material/HelpOutlineRounded'
import LocalPizzaIcon from '@mui/icons-material/LocalPizza'
import Pencil from '@mui/icons-material/Mode'
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw'
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  Icon,
  IconButton,
  Menu,
  Paper,
  Stack,
  Tooltip,
} from '@mui/material'
import { DrawPolygonByDraggingMode } from '@nebula.gl/edit-modes'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PivotWallMode } from './PivotWallMode'
import { PizzaSliceMode } from './PizzaSliceMode'

export interface ToolboxProps {
  disabled?: boolean
  onRedo?: () => void
  onUndo?: () => void
  mode: { id: string }
  onChangeMode: (change: { id: string; mode: any; modeConfig?: any }) => void
}

/**
 * @todo: adapt layout to mobile
 */
export const Toolbox = ({ disabled, onRedo, onUndo, mode, onChangeMode }: ToolboxProps) => {
  const { t } = useTranslation('@cibo/ControlPlotEditor')
  const { persona } = useAuth()
  const isAdmin = persona === 'admin'

  const [open, setOpen] = useState(false)
  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const anchorRef = useRef(null)

  const toggleHelpDialog = () => setHelpDialogOpen(prevValue => !prevValue)

  const handleClose = (event: Event) => {
    //@ts-ignore figure out correct useRef type to use
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  return (
    <>
      <Stack direction="row" data-testid="drawing-tools">
        <Paper variant="outlined" style={{ pointerEvents: 'all' }}>
          {isAdmin && (
            <ButtonGroup size="small" disableElevation sx={{ height: '100%' }}>
              <Button
                value={`pivotWall`}
                onClick={() => onChangeMode({ id: 'pivotWall', mode: PivotWallMode })}
                //maybe disable based on number of selected items
                variant={mode.id === 'pivotWall' ? 'contained' : 'text'}
                sx={{ paddingInline: 1.5 }}
                size="small"
              >
                <Tooltip placement="bottom" title={t(`pivotWall`)} arrow disableInteractive>
                  <Rotate90DegreesCcwIcon />
                </Tooltip>
              </Button>
              <Button
                value={`pizzaSlice`}
                onClick={() => onChangeMode({ id: 'pizzaSlice', mode: PizzaSliceMode })}
                //maybe disable based on number of selected items
                variant={mode.id === 'pizzaSlice' ? 'contained' : 'text'}
                sx={{ paddingInline: 1.5 }}
                size="small"
              >
                <Tooltip placement="bottom" title={t(`pizzaSlice`)} arrow disableInteractive>
                  <LocalPizzaIcon />
                </Tooltip>
              </Button>
              <Button
                value={`pencil`}
                onClick={() =>
                  onChangeMode({
                    id: 'pencil',
                    mode: DrawPolygonByDraggingMode,
                  })
                }
                //maybe disable based on number of selected items
                variant={mode.id === 'pencil' ? 'contained' : 'text'}
                sx={{ paddingInline: 1.5 }}
                size="small"
              >
                <Tooltip placement="bottom" title={t(`pencil`)} arrow disableInteractive>
                  <Pencil />
                </Tooltip>
              </Button>

              <Button
                value={`cut`}
                onClick={() =>
                  onChangeMode({
                    id: 'cut',
                    mode: DrawPolygonByDraggingMode,
                  })
                }
                //maybe disable based on number of selected items
                variant={mode.id === 'cut' ? 'contained' : 'text'}
                sx={{ paddingInline: 1.5 }}
                size="small"
              >
                <Tooltip placement="bottom" title={t(`cut`)} arrow disableInteractive>
                  <ContentCutIcon />
                </Tooltip>
              </Button>
            </ButtonGroup>
          )}
        </Paper>

        <Paper>
          <Stack direction="row" style={{ pointerEvents: 'all' }}>
            <ButtonGroup size="small">
              <IconButton aria-label={t('undo')} disabled={!onUndo} onClick={onUndo}>
                <Tooltip placement="bottom" title={`${t('undo')}`} arrow disableInteractive>
                  <Icon baseClassName="icon-Undo" />
                </Tooltip>
              </IconButton>
              <IconButton aria-label={t('redo')} disabled={!onRedo} onClick={onRedo}>
                <Tooltip placement="bottom" title={`${t('redo')}`} arrow disableInteractive>
                  <Icon baseClassName="icon-Redo" />
                </Tooltip>
              </IconButton>
            </ButtonGroup>

            <Divider orientation="vertical" variant="middle" flexItem />

            <IconButton aria-label={t('help')} onClick={toggleHelpDialog} size="small">
              <Tooltip placement="bottom" title={`${t('help')}`} arrow disableInteractive>
                <HelpIcon />
              </Tooltip>
            </IconButton>
          </Stack>
        </Paper>
      </Stack>

      <Dialog onClose={toggleHelpDialog} open={helpDialogOpen}>
        <DialogTitle>{t('controlPlotEditorHelp')}</DialogTitle>
        <DialogContent>
          <Markdown>{t('controlPlotHelpDescription')}</Markdown>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={toggleHelpDialog}>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      ></Menu>
    </>
  )
}
