import { FieldModel, TraitId } from '@cibo/core'
import { FriendlyError } from '@cibo/ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { countUniqueValues } from '../utils'

type OverrideWarningProps = {
  fieldModels: FieldModel[]
  traitId: TraitId
  year?: number
}

export const OverrideWarning = ({ fieldModels, traitId, year }: OverrideWarningProps) => {
  const { t } = useTranslation('@cibo/landmanager/OverrideWarning')
  const [uniqueValues, setUniqueValues] = useState(0)

  useEffect(() => {
    setUniqueValues(countUniqueValues({ fieldModels, traitId, year }))
  }, [])

  return uniqueValues > 1 ? (
    <FriendlyError
      type="info"
      textProps={{
        sx: { color: 'warning.main' },
        'data-testid': 'will-override',
      }}
      message={t('willOverride', { traitId })}
      small
    />
  ) : null
}
