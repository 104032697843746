import { RDUsdaUnderservedSmall } from '@cibo/core'
import { ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { MulticheckEditor } from '../../MulticheckEditor'

const keys = [
  'racialEthnicMinorityFarmer',
  'veteran',
  'beginnerFarmer',
  'limitedResources',
  'womanFarmer',
  'smallScale',
  'none',
]
/**
 * We assume that there is only ever a single detqail requirement for this component.
 */
export const RDUsdaUnderservedSmallTaskEditor = (
  props: ResourceDetailFeatureTaskEditorProps<RDUsdaUnderservedSmall>
) => {
  return (
    <MulticheckEditor
      keys={keys}
      ns="@cibo/profile/RDUsdaUnderservedSmallTaskEditor"
      detailKey="usdaUnderservedSmall"
      {...props}
    />
  )
}
