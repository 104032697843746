import {
  COMPONENTS_BY_TYPE,
  FERTILIZER_COMPONENTS,
  FERTILIZER_METHODS,
  FERTILIZER_TYPE,
  FERTILIZER_TYPE_OTHER,
  FERTILIZER_TYPE_RATE_UNIT,
  FERTILIZER_TYPE_UAN,
  FertilizerType,
  RDFertilizerEvent,
} from '@cibo/core'
import { AutocompleteField, CheckboxField, DatePickerField, SelectField } from '@cibo/ui'
import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  InputAdornment,
  InputLabel,
  Stack,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  createFilterOptions,
} from '@mui/material'
import { Field, Formik, FormikHelpers, useField } from 'formik'
import { TextField } from 'formik-mui'
import { MouseEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsRequired } from '../../useIsRequired'
import { useShowDetailAttribute } from '../../useShowDetailAttribute'
import { FertilizerEventValidationSchema } from '../schema'
import { AmountAndProduct } from './AmountAndProduct'
import { FertilizerEventEditorProps } from './FertilizerEventTypes'
import { FertilizerRateAndUnit } from './FertilizerRateAndUnit'

const fertilizerSearchStrings: Record<FertilizerType, string> = {
  starter: 'starter',
  nh3: 'NH3 82-0-0 Anhydrous Ammonia',
  uan28: 'UAN 28-0-0 Urea Ammonium Nitrate Solution 28% N',
  uan30: 'UAN 30-0-0 Urea Ammonium Nitrate Solution 30% N',
  uan32: 'UAN 32-0-0 Urea Ammonium Nitrate Solution 32% N',
  urea: 'Urea 46-0-0 synthetic urea',
  amp_10_34_0: '10-34-0 Ammonium Polyphosphate',
  ams_21_0_0_24: 'AMS 21-0-0-24 Ammonium Sulfate',
  ats_12_0_0_26: 'ATS 12-0-0-26 Ammonium Thiosulfate',
  dap_18_46_0: 'DAP 18-46-0 Diammonium Phosphate',
  mesz_12_40_0_10: 'MESZ 12-40-0-10 MESZ',
  map_11_52_0: 'MAP 11-52-0 Monoammonium Phosphate',
  other: 'other',
}

export const FertilizerEventEditor = ({
  expanded,
  filterInputs,
  name,
  onPressRemove,
  requirement,
  setExpanded,
  shouldDisableDate,
  onEditEvent,
  year,
}: FertilizerEventEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/FertilizerEditor')
  const [{ value }, field, { setValue, setTouched }] = useField<RDFertilizerEvent>(name)
  const [enterRateAndUnit, setEnterRateAndUnit] = useState(!!value.type && !value.nitrogenAmnt)

  const { error } = useField<RDFertilizerEvent>(`${name}.combined`)[1]
  const unitsHelper = useField<string>(`${name}.units`)[2]

  const combinedError = error

  useEffect(() => {
    if (FERTILIZER_TYPE_UAN.includes(value?.type)) {
      unitsHelper.setValue('gallonsPerAcre')
    }
    if (!value.type) {
      setEnterRateAndUnit(false)
    } else if (!FERTILIZER_TYPE_RATE_UNIT.includes(value?.type) && enterRateAndUnit) {
      setEnterRateAndUnit(false)
    }
  }, [value?.type])

  const defaultMonth = new Date(year, 3)

  const fertilizerTypeFilterOptions = useMemo(
    () =>
      createFilterOptions({
        matchFrom: 'any',
        stringify: (option: FertilizerType) => fertilizerSearchStrings[option] || option,
      }),
    []
  )

  const comboFieldStyle = useMemo(
    () => ({
      zIndex: 0,
      marginBottom: 2,
      '& .MuiTextField-root': {
        flex: 1,
      },
      '& .MuiTextField-root .MuiOutlinedInput-notchedOutline': {
        borderRadius: 0,
        marginLeft: '-1px',
        zIndex: 0,
        borderColor: !!combinedError ? 'error.main' : undefined,
      },
      '& .MuiTextField-root:first-of-type .MuiOutlinedInput-notchedOutline': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
      },
      '& .MuiTextField-root:last-child .MuiOutlinedInput-notchedOutline': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
      },
      '& .MuiError .MuiOutlinedInput-notchedOutline': {
        zIndex: 1,
      },
    }),
    [error]
  )
  const isRequired = useIsRequired(requirement)
  const show = useShowDetailAttribute<RDFertilizerEvent>(filterInputs)

  const validationSchema = FertilizerEventValidationSchema({ t, requirement })

  const handleSubmit = (
    values: RDFertilizerEvent,
    { setSubmitting }: FormikHelpers<RDFertilizerEvent>
  ) => {
    setTouched(true, false)
    setValue(values)
    setSubmitting(false)
    onEditEvent(false)
    setExpanded(false)
  }

  return (
    <Formik
      initialValues={
        value ||
        ({ date: null, type: null, method: null, units: null } as unknown as RDFertilizerEvent)
      }
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ isValid, resetForm, submitForm, values, dirty, errors, setFieldValue }) => {
        // @ts-ignore combinedPercent is a "synthetic" field to generate this error / not a form value
        const combinedPercentError = errors.combinedPercent
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (value && value.type !== values.type) {
            FERTILIZER_COMPONENTS.map(component =>
              setFieldValue(
                component,
                COMPONENTS_BY_TYPE[values.type] ? COMPONENTS_BY_TYPE[values.type][component] : ''
              )
            )
          }
        }, [values.type])

        const displayRateAndUnit =
          enterRateAndUnit && FERTILIZER_TYPE_RATE_UNIT.includes(values.type)

        return (
          <TableRow>
            <TableCell sx={{ paddingBlock: 0, borderBottomWidth: expanded ? 1 : 0 }} colSpan={5}>
              <Collapse in={expanded}>
                <Stack sx={{ paddingBlock: 1 }} spacing={2}>
                  {(show('type') || show('date')) && (
                    <Box>
                      <Grid spacing={2} container>
                        {show('date') && (
                          <Grid size={{ xs: 12, md: 6 }}>
                            <FormControl fullWidth required>
                              <FormLabel>{t('date')}</FormLabel>
                              <DatePickerField
                                name={`date`}
                                minDate={new Date(year - 1, 6, 1)}
                                maxDate={new Date(year + 1, 0, 1)}
                                defaultCalendarMonth={defaultMonth}
                                shouldDisableDate={shouldDisableDate}
                                InputProps={{
                                  //@ts-ignore this will be passed to the input element, but is not in the type definition
                                  'data-testid': `${name}.date`,
                                }}
                              />
                            </FormControl>
                          </Grid>
                        )}

                        {show('type') && (
                          <Grid size={{ xs: 12, md: 6 }}>
                            <FormControl fullWidth required>
                              <FormLabel>{t('type')}</FormLabel>
                              <AutocompleteField
                                options={FERTILIZER_TYPE}
                                id={`${name}.type`}
                                filterOptions={fertilizerTypeFilterOptions}
                                name={`type`}
                                getOptionLabel={option => t(option)}
                                data-testid={`${name}.type`}
                              />
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  )}
                  <Box>
                    <Collapse
                      in={!!values?.type && FERTILIZER_TYPE_RATE_UNIT.includes(values?.type)}
                    >
                      <Stack direction="row" spacing={1} alignItems="center" paddingBottom={1}>
                        <Typography>{t('enterAs')}</Typography>
                        <ToggleButtonGroup
                          exclusive
                          size="small"
                          disabled={!FERTILIZER_TYPE_RATE_UNIT.includes(values?.type)}
                          value={enterRateAndUnit ? 'rate' : 'amnt'}
                          onChange={(event: MouseEvent<HTMLElement>, newValue: string) => {
                            if (newValue === 'rate') {
                              setFieldValue('nitrogenAmnt', '', true)
                            } else {
                              setFieldValue('rate', '', true)
                            }
                            setEnterRateAndUnit(newValue === 'rate')
                          }}
                        >
                          <ToggleButton value="amnt" data-testid="nitrogen-toggle">
                            {t('nitrogenAmntPerAcre')}
                          </ToggleButton>
                          <ToggleButton value="rate" data-testid="rate-and-unit-toggle">
                            {t('rateAndUnit')}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Stack>
                    </Collapse>

                    <FertilizerRateAndUnit in={displayRateAndUnit} name={name} />

                    <AmountAndProduct
                      in={!!values?.type && !displayRateAndUnit}
                      productRequired={FERTILIZER_TYPE_OTHER.includes(values?.type)}
                      name={name}
                    />
                  </Box>

                  <Collapse in={!!values.type}>
                    <Grid size={{ xs: 12, md: 4 }}>
                      <FormControl required={isRequired('method')} fullWidth>
                        <FormLabel>{t('method')}</FormLabel>

                        <SelectField
                          fieldName={`method`}
                          options={FERTILIZER_METHODS}
                          renderOption={t}
                          data-testid={`${name}.method`}
                        />
                      </FormControl>
                    </Grid>
                    <Stack spacing={0}>
                      {show('slowRelease') && (
                        <Stack direction="row" alignItems="center" flex={1}>
                          <CheckboxField name={`slowRelease`} data-testid={`${name}.slowRelease`} />
                          <InputLabel
                            htmlFor={`${name}.slowRelease`}
                            required={isRequired('slowRelease')}
                          >
                            {t('slowRelease')}
                          </InputLabel>
                        </Stack>
                      )}
                      {show('inhibitor') && (
                        <Stack direction="row" alignItems="center">
                          <CheckboxField name={`inhibitor`} data-testid={`${name}.inhibitor`} />
                          <InputLabel
                            htmlFor={`${name}.inhibitor`}
                            required={isRequired('inhibitor')}
                          >
                            {t('inhibitor')}
                          </InputLabel>
                        </Stack>
                      )}
                      {show('ammonium') && (
                        <Stack direction="row" alignItems="center">
                          <CheckboxField name={`ammonium`} data-testid={`${name}.ammonium`} />
                          <InputLabel
                            htmlFor={`${name}.ammonium`}
                            required={isRequired('ammonium')}
                          >
                            {t('ammonium')}
                          </InputLabel>
                        </Stack>
                      )}
                    </Stack>

                    <FormControl fullWidth>
                      <Stack direction="row">
                        {FERTILIZER_COMPONENTS.filter(component => show(component)).map(
                          component => (
                            <FormLabel
                              key={`${name}.${component}`}
                              htmlFor={`${name}.${component}`}
                              sx={{ width: '25%' }}
                              required={isRequired(component)}
                            >
                              {t('fertilizerComponent', { context: component })}
                            </FormLabel>
                          )
                        )}
                      </Stack>
                      <Stack direction="row" sx={comboFieldStyle}>
                        {FERTILIZER_COMPONENTS.filter(component => show(component)).map(
                          component => (
                            <Field
                              key={`${name}.${component}`}
                              name={`${component}`}
                              component={TextField}
                              inputProps={{
                                min: 0,
                                disabled: !!COMPONENTS_BY_TYPE[values.type],
                                max: 100,
                                type: 'number',
                                'data-testid': `${name}.${component}`,
                              }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}
                            />
                          )
                        )}
                      </Stack>

                      {!!combinedPercentError && (
                        <Typography color="error" variant="caption">
                          {combinedPercentError}
                        </Typography>
                      )}
                    </FormControl>
                  </Collapse>

                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="caption">{t('requiredFields')}</Typography>
                    <Stack direction="row" justifyContent="flex-end" spacing={1}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          !field.initialValue && !field.touched && onPressRemove()
                          onEditEvent(false)
                          resetForm()
                          setExpanded(false)
                        }}
                        size="small"
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        data-testid="add-fertilizer-event"
                        variant="contained"
                        onClick={() => submitForm()}
                        disabled={!isValid || !dirty}
                        size="small"
                      >
                        {t(!!field.initialValue ? 'update' : 'add')}
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Collapse>
            </TableCell>
          </TableRow>
        )
      }}
    </Formik>
  )
}
