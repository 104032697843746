import { FieldModel, TraitId } from '@cibo/core'
import { QueryKey } from '@tanstack/react-query'
import { groupBy, mapObjIndexed, pipe, pluck } from 'ramda'
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import { TraitFeature } from '../TraitFeaturesById'
import { useSaveBulkDetails } from '../queries'
import { DEFAULT_VALUES, RollupQuestionContext } from './RollupQuestionContext'
import { DetailStatus } from './types'
import { getDetailRollupStatuses, getDetailStatuses } from './utils'

type Props = {
  fieldModels: FieldModel[]
  queryKey?: QueryKey
  traitId?: TraitId
  years?: number[]
  children?: ReactNode
}

export const RollupQuestionProvider = ({
  children,
  fieldModels,
  traitId,
  years,
}: PropsWithChildren<Props>) => {
  const [formParams, setFormParams] = useState<any>()
  const [rollupRequested, setRollupRequested] = useState<boolean | undefined>()
  const { mutateAsync: saveBulkDetails } = useSaveBulkDetails()

  const rollup =
    traitId &&
    TraitFeature.forTraitId(traitId)?.rollups &&
    TraitFeature.forTraitId(traitId)?.rollups?.default

  useEffect(() => {
    if (!rollup) {
      setRollupRequested(undefined)
      return
    }

    const rollupDetailStatuses = getDetailRollupStatuses(rollup, fieldModels, years)

    const detailsPresent = rollupDetailStatuses.some(({ detail }) => detail !== undefined)
    const missingDetails = rollupDetailStatuses.some(({ detail }) => detail === undefined)
    const conflictingDetails = rollupDetailStatuses.some(
      ({ detail, matchesRollup }) => !!detail && !matchesRollup
    )

    if (!detailsPresent) {
      setRollupRequested(undefined)
    } else if (!missingDetails && !conflictingDetails) {
      setRollupRequested(true)
    } else if (!missingDetails && conflictingDetails) {
      setRollupRequested(false)
    } else {
      setRollupRequested(undefined)
    }
  }, [rollup])

  if (!rollup) {
    // can't do much here - maybe traitId should be required?
    if (!traitId) {
      return <>{children}</>
    }

    const nonRollupDetailStatuses = getDetailStatuses(traitId, fieldModels, years)

    // provide some basic statuses that can be used to disable Continue button
    return (
      <RollupQuestionContext.Provider
        value={{
          ...DEFAULT_VALUES,
          detailsPresent: nonRollupDetailStatuses.some(({ detail }) => detail !== undefined),
          detailsMissing: !nonRollupDetailStatuses.every(({ detail }) => detail !== undefined),
          fieldModels,
        }}
      >
        {children}
      </RollupQuestionContext.Provider>
    )
  }

  const detailStatuses = getDetailRollupStatuses(
    rollup,
    fieldModels,
    years?.length ? years : undefined
  )

  const mismatchDetails = detailStatuses.filter(({ matchesRollup }) => !matchesRollup)

  const commitChanges = () => {
    if (!rollup) return Promise.resolve()
    const baseDetail = rollup.detailBaseValue(formParams)

    if (years?.length) {
      const resourceIdsByYear = pipe(
        groupBy<DetailStatus>(ds => `${ds.year}`),
        mapObjIndexed(pluck('resourceId'))
      )(mismatchDetails)

      const promises: Promise<any>[] = []
      years.forEach(year => {
        const resourceIds = resourceIdsByYear[`${year}`]
        if (resourceIds) {
          promises.push(
            saveBulkDetails({
              resourceIds,
              details: [{ ...baseDetail, year }],
            })
          )
        }
      })

      return Promise.all(promises)
    } else {
      return saveBulkDetails({
        resourceIds: pluck('resourceId')(mismatchDetails),
        details: [baseDetail],
      })
    }
  }

  return (
    <RollupQuestionContext.Provider
      value={{
        detailsPresent: detailStatuses.some(({ detail }) => detail !== undefined),
        detailsMissing: !detailStatuses.every(({ detail }) => detail !== undefined),
        rollup,
        formParams,
        setFormParams,
        rollupRequested,
        setRollupRequested,
        unsavedUpdates: mismatchDetails.length,
        commitChanges,
        fieldModels,
      }}
    >
      {children}
    </RollupQuestionContext.Provider>
  )
}
