import { Array2d, assertBBoxArray, bbox2dFromBboxArray, mergeFeatureCollection } from '@cibo/core'
import {
  GEOMETRY_SNAPSHOTS,
  GeoJSONLayerState,
  LayerContext,
  LayerRenderer,
  MAPBOX_STYLES,
  MAPBOX_TOKEN,
  MapStyles,
  NavigationControl,
  ViewState,
  fitBoundsOfBbox,
  fitBoundsOfFeature,
  getFeatureLineColor,
  themeForMapStyle,
  useGeometryEditorStateContext,
  useViewportContext,
} from '@cibo/mapgl'
import { US_CENTER } from '@cibo/ui'
import { partition, propEq } from 'ramda'
import { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ControlGroup } from '../ControlGroupsAPI'
//@ts-ignore soon: https://deck.gl/docs/get-started/using-with-typescript
import { DeckGL } from '@deck.gl/react'
import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import * as Sentry from '@sentry/react'
import intersect from '@turf/intersect'
import {
  Feature,
  FeatureCollection,
  MultiPolygon,
  Polygon,
  Position,
  bbox,
  difference,
  featureCollection,
  polygon,
} from '@turf/turf'
import { useHotkeys } from 'react-hotkeys-hook'
import { ViewState as MapGlViewState } from 'react-map-gl'
import { ControlGroupFields } from './ControlGroupFields'
import { LayerControls } from './LayerControls'
import { PivotWallMode, generateId } from './PivotWallMode'

import { Box, Grid2 as Grid, Paper, Stack, ThemeProvider, useTheme } from '@mui/material'
import { Map } from 'react-map-gl/dist/es5'
import { Toolbox } from './Toolbox'

const DEFAULT_VIEWSTATE = {
  longitude: US_CENTER[0],
  latitude: US_CENTER[1],
  zoom: 4,
}

type ControlPlotEditorProps = {
  children?: (props: { touched: boolean; features: FeatureCollection }) => ReactNode
  controlGroup?: ControlGroup
  disabled?: boolean
  fieldShapes?: FeatureCollection<Polygon | MultiPolygon>
  initialPosition?: Position
  onChange?: (props: { features: FeatureCollection; touched: boolean }) => void
}

const getControlPlotFeature = (controlGroup?: ControlGroup) => {
  const currentPlotDetail = controlGroup?.details.find(propEq('traitId', 'geometry'))
  return currentPlotDetail?.input?.feature
}

const PENDING_COLOR = [134, 198, 208, 102]
const INVALID_COLOR = [240, 30, 30, 100]
const VALID_COLOR = [30, 240, 30, 100]
const UNPAIRED_COLOR = [110, 110, 110, 110]

export const ControlPlotEditor = ({
  children,
  controlGroup,
  disabled,
  fieldShapes,
  onChange,
}: ControlPlotEditorProps) => {
  const theme = useTheme()
  const { mapStyle = MapStyles.SATELLITE } = useContext(LayerContext)
  const [mode, setMode] = useState({ id: 'pivotWall', mode: PivotWallMode })

  const currentFeature = useMemo(() => getControlPlotFeature(controlGroup), [controlGroup])

  const [initialFeatures, setInitialFeatures] = useState(featureCollection([]))
  const [geometryGroup, setGeometryGroup] = useState<string>()

  useEffect(() => {
    if (
      !initialFeatures.features[0]?.id ||
      (controlGroup?.id && controlGroup.id !== geometryGroup)
    ) {
      setInitialFeatures(featureCollection([currentFeature]))
      setGeometryGroup(controlGroup?.id)
    }
  }, [currentFeature, controlGroup])

  const getFeatureFillColor = (feature: Feature) => {
    // we don't have updated status for this feature
    if (!controlGroup || currentFeature?.id !== feature.id) {
      return PENDING_COLOR
    }

    if (controlGroup.modelUnpaired) {
      return UNPAIRED_COLOR
    }

    switch (controlGroup?.status) {
      case 'pending':
        return PENDING_COLOR
      case 'invalid':
        return INVALID_COLOR
      case 'valid':
        return VALID_COLOR
    }
  }

  const [initialViewState, setInitialViewState] = useState<ViewState>(DEFAULT_VIEWSTATE)
  const [viewState, setViewState] = useState(initialViewState)

  const container = useRef<HTMLDivElement>(null)

  const [modeConfig, setModeConfig] = useState<any>({})

  useEffect(() => {
    if (!fieldShapes || fieldShapes.features.length === 0 || !container.current) return

    const bounds = bbox(fieldShapes)
    assertBBoxArray(bounds)

    const newViewState = fitBoundsOfBbox({
      boundingBox: bbox2dFromBboxArray(bounds) as Array2d,
      rect: container.current.getBoundingClientRect(),
      // @ts-ignore
      viewState: { ...viewState },
      padding: 40,
    })

    setInitialViewState({ ...newViewState })

    const polygonFeatures: Feature<Polygon>[] = []

    const fieldShapeProperties = { controlGroup, fieldCount: fieldShapes.features.length }

    fieldShapes.features.forEach(fieldFeature => {
      if (fieldFeature.geometry.type === 'MultiPolygon') {
        fieldFeature.geometry.coordinates.forEach((polygonCoordinates: Position[][]) => {
          try {
            polygonFeatures.push(
              polygon(polygonCoordinates, {
                ...fieldShapeProperties,
                parentField: fieldFeature.geometry,
              })
            )
          } catch (e) {
            // report but do not crash
            Sentry.captureException(e)
          }
        })
      }
      if (fieldFeature.geometry.type === 'Polygon') {
        polygonFeatures.push({ ...fieldFeature, properties: fieldShapeProperties } as Feature<
          Polygon
        >)
      }
    })

    setModeConfig({ fieldShapes: featureCollection(polygonFeatures) })
  }, [fieldShapes])

  const {
    editState,
    features,
    setEditState,
    touched,
    previewFeatures,
    setPreviewFeatures,
  } = useGeometryEditorStateContext()
  const { setViewport } = useViewportContext()

  useEffect(() => {
    const forceRedraw = () => {
      handleRecenterMap()
    }

    window.addEventListener('resize', forceRedraw)

    return () => window.removeEventListener('resize', forceRedraw)
  })

  /**
   * reset edit states on initialFeatures change
   */
  useEffect(() => {
    setEditState({
      editLayer: {
        features: initialFeatures,
        selectedIndexes: initialFeatures.features.length > 0 ? [0] : ([] as number[]),
      },
      redoStack: [],
      undoStack: [],
      transforming: false,
    })
    setPreviewFeatures(initialFeatures)
  }, [initialFeatures])

  const handleRecenterMap = () => {
    if (!container.current || !fieldShapes) {
      return
    }

    if (fieldShapes.features?.length > 0) {
      setInitialViewState({
        ...fitBoundsOfFeature({
          features: fieldShapes,
          rect: container.current.getBoundingClientRect(),
          //@ts-ignore
          viewState,
        }),
      })
    } else {
      setInitialViewState(DEFAULT_VIEWSTATE)
    }
  }

  const handleUndo =
    editState.undoStack.length > 0
      ? () => {
          const editLayer = editState.undoStack.shift() as GeoJSONLayerState
          setEditState({
            ...editState,
            editLayer,
            undoStack: editState.undoStack, //already mutated above
            redoStack: [editState.editLayer, ...editState.redoStack],
          })
          setPreviewFeatures(editLayer.features)
          onChange &&
            onChange({
              features: editLayer.features,
              touched: true,
            })
        }
      : undefined

  const handleRedo =
    editState.redoStack.length > 0
      ? () => {
          const editLayer = editState.redoStack.shift() as GeoJSONLayerState
          setEditState({
            ...editState,
            editLayer,
            undoStack: [editState.editLayer, ...editState.undoStack],
            redoStack: editState.redoStack, //already mutated above
          })
          setPreviewFeatures(editLayer.features)
          onChange &&
            onChange({
              features: editLayer.features,
              touched: true,
            })
        }
      : undefined

  useHotkeys('cmd+z, ctrl+z', () => handleUndo?.(), [editState])
  useHotkeys('cmd+shift+z, ctrl+shift+z', () => handleRedo?.(), [editState])

  //@ts-ignore
  const featureLayer = new EditableGeoJsonLayer({
    id: 'geojson-layer',
    data: previewFeatures,
    selectedFeatureIndexes: [],
    mode: mode.mode,
    modeConfig,
    getEditHandlePointColor: (handle: Feature) => {
      switch (handle.properties?.editHandleType) {
        case 'existing':
          return [0x0, 0x0, 0x0, 0xff]
        case 'intermediate':
        default:
          return [0x0, 0x0, 0x0, 0x80]
      }
    },
    getLineColor: (feature: Feature, isSelected: boolean) =>
      getFeatureLineColor(mapStyle, isSelected),
    //@ts-ignore
    onEdit: ({ updatedData, editType }) => {
      if (GEOMETRY_SNAPSHOTS.includes(editType)) {
        let updatedFeature: Feature | undefined | null

        switch (mode.id) {
          case 'cut':
            const [newFeatures, oldFeatures] = partition(
              ({ id }: Feature) => typeof id === 'undefined',
              updatedData.features
            )

            if (!oldFeatures?.length) break

            const oldFeature = mergeFeatureCollection(
              featureCollection(oldFeatures as Feature<Polygon | MultiPolygon>[])
            )
            const newFeature = mergeFeatureCollection(
              featureCollection(newFeatures as Feature<Polygon | MultiPolygon>[])
            )

            updatedFeature = oldFeature && newFeature && difference(oldFeature, newFeature)

            break
          default:
          case 'controlPlot':
          case 'pencil':
            updatedFeature = mergeFeatureCollection(featureCollection(updatedData.features))
            if (fieldShapes && updatedFeature) {
              const fieldGeometry = mergeFeatureCollection(fieldShapes) as Feature<
                Polygon | MultiPolygon
              >

              updatedFeature = intersect(fieldGeometry, updatedFeature as Feature<MultiPolygon>)
            }
            break
        }

        if (updatedFeature) {
          updatedFeature.id = generateId()
        }

        const updatedFC = featureCollection(updatedFeature ? [updatedFeature] : [])

        const editLayer = {
          features: updatedFC,
          selectedIndexes:
            editType === 'addFeature'
              ? [updatedFC.features.length - 1]
              : editState.editLayer.selectedIndexes,
        } as GeoJSONLayerState

        const newEditState = {
          ...editState,
          editLayer,
          undoStack: [editState.editLayer, ...editState.undoStack],
          redoStack: [],
        }

        setEditState(newEditState)

        if (onChange) {
          onChange({
            features: newEditState.editLayer.features,
            touched: newEditState.undoStack.length > 0,
          })
        }

        setPreviewFeatures(updatedFC)
      } else {
        setPreviewFeatures(updatedData)
      }
    },
    _subLayerProps: {
      geojson: {
        getFillColor: getFeatureFillColor,
      },
    },
  })

  const handleZoomIn = () => {
    if (!!viewState) {
      setInitialViewState({ ...viewState, zoom: viewState.zoom * 1.1 })
    }
  }
  const handleZoomOut = () => {
    if (!!viewState) {
      setInitialViewState({ ...viewState, zoom: viewState.zoom * 0.9 })
    }
  }

  const handleViewStateChange = (newViewState: { viewState: ViewState }) => {
    setViewState(newViewState.viewState)
  }

  return (
    <div
      style={{ flex: 1, position: 'relative', display: 'flex' }}
      onContextMenu={evt => evt.preventDefault()}
      ref={container}
    >
      <ThemeProvider theme={themeForMapStyle(theme, mapStyle)}>
        <>{/* @ts-ignore */}</>
        <DeckGL
          initialViewState={initialViewState}
          controller={{
            doubleClickZoom: false,
            inertia: true,
          }}
          pickingRadius={20}
          layers={[featureLayer]}
          getCursor={featureLayer.getCursor.bind(featureLayer)}
          onViewStateChange={handleViewStateChange}
        >
          {({ viewState }: { viewState: MapGlViewState }) => {
            !disabled && setViewport(viewState)
          }}
          <Map
            mapStyle={
              (mapStyle && MAPBOX_STYLES[mapStyle][theme.palette.mode]) ||
              MAPBOX_STYLES.SATELLITE[theme.palette.mode]
            }
            mapboxAccessToken={MAPBOX_TOKEN}
          >
            <LayerRenderer county township parcel />

            <ControlGroupFields fieldShapes={fieldShapes} />
          </Map>
        </DeckGL>
      </ThemeProvider>

      <Stack
        justifyContent="space-between"
        sx={{ position: 'absolute', flex: 1, pointerEvents: 'none' }}
      >
        <Grid sx={{ padding: 2, paddingBottom: 5 }} container>
          <Grid size={{ xs: 6 }}>
            <Toolbox
              disabled={disabled}
              mode={mode}
              onChangeMode={setMode}
              onUndo={handleUndo}
              onRedo={handleRedo}
            />
          </Grid>
        </Grid>
      </Stack>

      <LayerControls>
        {!!children &&
          typeof children === 'function' &&
          children({
            features,
            touched,
          })}
        <Box display="inline-block">
          <Paper>
            <NavigationControl
              onZoomIn={handleZoomIn}
              onZoomOut={handleZoomOut}
              onRecenter={handleRecenterMap}
            />
          </Paper>
        </Box>
      </LayerControls>
    </div>
  )
}
