/* eslint-disable complexity */
import { ProgramBinding } from '@cibo/core/src/types/Program/Binding'
import { AuthUserPermission, usePermissions } from '@cibo/profile'
import { ENGAGEMENT_ROUTES, EngagementRouteParams } from '@cibo/ui'
import Check from '@mui/icons-material/Check'
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  alpha,
  styled,
} from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath, useParams } from 'react-router-dom'
import { useProgramEngagementContext } from '../../../context'
import { useProgramContent } from '../../../queries'

const StepStatus = ({
  available,
  review,
  valid,
}: {
  available?: boolean
  review?: boolean
  valid?: boolean
}) => {
  const { t } = useTranslation('@cibo/impact/StepStatus')

  return review ? (
    <Typography variant="caption">{t('review')}</Typography>
  ) : available ? (
    <Typography color={valid ? 'success.main' : 'warning.main'} variant="caption">
      {valid ? t('complete') : t('incomplete')}
    </Typography>
  ) : (
    <Typography variant="caption">{t('unavailable')}</Typography>
  )
}

const TaskButton = styled(ListItemButton)(({ theme }) => ({
  '&.MuiListItemButton-root': {
    backgroundColor: theme.palette.background.default,

    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.08),
    },

    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.04),
    },
  },
}))

export const ProgramMenu = () => {
  const { stageId = 'apply', ...routeParams } = useParams<EngagementRouteParams>()
  const { program } = useProgramEngagementContext()
  const [binding, setBinding] = useState<ProgramBinding>()

  useEffect(() => {
    if (program.data) {
      setBinding(program.data?.stage(stageId))
    }
  }, [program?.dataUpdatedAt, stageId])

  return binding ? (
    <List component="div" disablePadding>
      <ProgramMenuItem binding={[binding]} stageId={stageId} {...routeParams} />
    </List>
  ) : null
}

const ProgramMenuItem = ({
  binding,
  stageId,
  stepId,
  taskId,
}: { binding: ProgramBinding[] } & EngagementRouteParams) => {
  const { can } = usePermissions()
  const { basePath, engagement, program } = useProgramEngagementContext()
  const content = useProgramContent(program.data?.programId)
  const engagementId = engagement.data?.id

  const reviewingUnavailableStage = engagement.data?.getStepStatus(stageId)?.available === false

  return !!content.data && !!program.data ? (
    <>
      {binding?.map(({ children, id, type }, index) => {
        const showChildren =
          type === 'stage' ||
          (type === 'step' && stepId === id && !!engagementId && !!taskId) ||
          (type === 'task' && taskId === id && !!engagementId)

        const status = engagement.data?.getStatus({ id })
        const stepContent = program.data?.stepContent(id)?.content
        const taskContent = program.data?.taskContent(id)

        const disabled = !reviewingUnavailableStage && (!status?.available || !engagementId)

        return (
          <Fragment key={id}>
            {type === 'step' ? (
              <>
                <ListItem
                  key={id}
                  data-testid={`step-listitem-${id}`}
                  divider
                  secondaryAction={
                    !!engagementId && (
                      <StepStatus
                        valid={status?.status.progress?.completed}
                        available={status?.available}
                        review={reviewingUnavailableStage}
                      />
                    )
                  }
                  disablePadding
                >
                  <ListItemButton
                    component={Link}
                    to={
                      disabled
                        ? '#'
                        : generatePath(`${basePath}${ENGAGEMENT_ROUTES.STEP}`, {
                            engagementId,
                            stepId: id,
                            stageId,
                          })
                    }
                    //@todo figure out why a ListItemButton has 0 height meaning we have these whack padding values
                    sx={{ paddingBlock: 5, paddingInline: 2 }}
                    disabled={disabled}
                  >
                    <ListItemText
                      primary={stepContent?.title || status?.id}
                      secondary={stepContent?.subTitle}
                    />
                  </ListItemButton>
                </ListItem>
              </>
            ) : type === 'task' && !taskContent?.hidden ? (
              <ListItem
                key={id}
                data-testid={`task-listitem-${id}`}
                secondaryAction={
                  status?.status.progress?.completed ? (
                    // debug user can see if a task is completed but not eligible
                    <Typography
                      color={
                        can({ useAll: [AuthUserPermission.DEBUG_USER] }) &&
                        !status?.status.eligibility?.eligible
                          ? 'warning.light'
                          : 'success.light'
                      }
                    >
                      <Check fontSize="medium" />
                    </Typography>
                  ) : null
                }
                disablePadding
              >
                <TaskButton
                  key={id}
                  disabled={disabled}
                  //@ts-ignore
                  component={Link}
                  to={
                    disabled
                      ? '#'
                      : generatePath(`${basePath}${ENGAGEMENT_ROUTES.TASK}`, {
                          engagementId,
                          stepId,
                          taskId: id,
                          stageId,
                        })
                  }
                  selected={id === taskId}
                  sx={{ paddingInline: 2, paddingBlock: 1.5 }}
                  divider
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <ListItemText
                      primary={taskContent?.content.title || status?.id}
                      secondary={taskContent?.content.subTitle}
                    />
                  </Stack>
                </TaskButton>
              </ListItem>
            ) : null}
            <Collapse in={!!showChildren}>
              {children.map(child => (
                <ProgramMenuItem
                  key={child.id}
                  binding={[child]}
                  stageId={stageId}
                  stepId={type === 'step' ? id : stepId}
                  taskId={taskId}
                />
              ))}
            </Collapse>
          </Fragment>
        )
      })}
    </>
  ) : null
}
