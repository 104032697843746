import { NATIVE_VEGETATION_TYPES, NativeConversionInput } from '@cibo/core'
import { CheckboxField, SelectField } from '@cibo/ui'
import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  Stack,
} from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import { Field, FieldProps } from 'formik'
import { RadioGroup } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { DetailEditorProps } from '../types'

const TARGET_DATE_YEAR_SCOPE = 20
const currentYear = new Date().getFullYear()
const TARGET_YEARS = new Array(TARGET_DATE_YEAR_SCOPE).fill(0).map((v, i) => currentYear - 1 - i)

export const NativeConversionEditor = ({ name = 'nativeConversionEditor' }: DetailEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/NativeConversionEditor')

  return (
    <Field name={name} id={name}>
      {({ field }: FieldProps<NativeConversionInput>) => (
        <Stack spacing={2}>
          <Stack>
            <InputLabel htmlFor={`${name}.converted`}>{t('whenConverted')}</InputLabel>

            <Field component={RadioGroup} name={`${name}.converted`}>
              <FormControlLabel
                value={'yes'}
                control={<Radio />}
                label={`${t('yes')}`}
                data-testid="input.yes"
              />
              <FormControlLabel
                value={'no'}
                control={<Radio />}
                label={`${t('no')}`}
                data-testid="input.no"
              />
              <FormControlLabel
                value={'unsure'}
                control={<Radio />}
                label={`${t('unsure')}`}
                data-testid="input.unsure"
              />
            </Field>
          </Stack>
          <Collapse in={field.value?.converted === 'yes'}>
            <Stack direction="row" spacing={3}>
              <FormControl sx={{ flex: 1 }}>
                <FormLabel>{t('nativeVegetation')}</FormLabel>

                <SelectField
                  fieldName={`${name}.nativeVegetation`}
                  options={NATIVE_VEGETATION_TYPES}
                  renderOption={context => t('originalUse', { context })}
                />
              </FormControl>
              <Box sx={{ flex: 1 }}>
                <FormControl>
                  <FormLabel>{t('conversionYear')}</FormLabel>

                  <SelectField
                    fieldName={`${name}.conversionYear`}
                    options={TARGET_YEARS.map(year => `${year}`)}
                    renderOption={option => <>{option}</>}
                  />
                </FormControl>

                <Stack direction="row" alignItems="center">
                  <CheckboxField name={`${name}.unsureOfYear`} data-testid="unsureOfYear" />
                  <InputLabel htmlFor={`${name}.unsureOfYear`}>{t('unsureOfYear')}</InputLabel>
                </Stack>
              </Box>
            </Stack>
          </Collapse>
        </Stack>
      )}
    </Field>
  )
}
