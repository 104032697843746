import { FERTILIZER_COMPONENTS } from '@cibo/core'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import Warning from '@mui/icons-material/Warning'
import { useEffect } from 'react'
import { FertilizerEventDisplayProps } from './FertilizerEventTypes'

export const FertilizerEventDisplay = ({
  editing,
  expanded,
  name,
  onPressRemove,
  setExpanded,
}: FertilizerEventDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/FertilizerEditor')
  const [field, meta, actions] = useField(name)
  useEffect(() => {
    actions.setTouched(true, true)
    actions.setTouched(false)
  }, [])
  return (
    <TableRow>
      <TableCell sx={{ minWidth: '100px', borderBottomWidth: expanded ? 1 : 0 }}>
        <Collapse in={expanded}>{t('eventDate', { date: new Date(field.value.date) })}</Collapse>
      </TableCell>
      <TableCell sx={{ borderBottomWidth: expanded ? 1 : 0 }}>
        <Collapse in={expanded}>
          <Stack>
            <Typography variant="body1">
              {field.value.product || t(field.value.type || 'unset')}{' '}
              {field.value.type === 'other' && (
                <Typography variant="caption">({t(field.value.type)})</Typography>
              )}
            </Typography>
            <Typography variant="body2">
              {`${field.value.slowRelease ? t('slowRelease') : ''}${
                field.value.slowRelease && field.value.inhibitor ? ' & ' : ''
              }${field.value.inhibitor ? t('inhibitorShort') : ''}`}
            </Typography>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
            >
              {FERTILIZER_COMPONENTS.map(component =>
                !!field.value[component] ? (
                  <Typography key={component} variant="caption">
                    {t(component, { value: field.value[component] })}
                  </Typography>
                ) : null
              )}
              {field.value.ammonium && (
                <Typography variant="caption">{t('ammoniumShort')}</Typography>
              )}
            </Stack>
          </Stack>
        </Collapse>
      </TableCell>
      <TableCell sx={{ borderBottomWidth: expanded ? 1 : 0 }}>
        <Collapse in={expanded}>
          <Stack>
            <Typography>
              {field.value.nitrogenAmnt
                ? t('amntLbsNac', { value: field.value.nitrogenAmnt })
                : t('applicationRateDisplay', {
                    rate: field.value.rate,
                    units: t(field.value.units),
                  })}
            </Typography>
            <Typography variant="caption">{t(field.value.method)}</Typography>
          </Stack>
        </Collapse>
      </TableCell>
      <TableCell sx={{ borderBottomWidth: expanded ? 1 : 0 }}>
        <Collapse in={expanded}>
          <Stack direction="row">
            <IconButton
              aria-label={t('editEvent')}
              onClick={() => setExpanded(true)}
              disabled={editing}
            >
              <EditIcon />
            </IconButton>
            <IconButton aria-label={t('removeEvent')} onClick={onPressRemove} disabled={editing}>
              <ClearIcon />
            </IconButton>
            {!!meta.error && (
              <Box sx={{ m: 1, display: 'flex', flex: '0 0 auto' }}>
                <Warning color="error" />
              </Box>
            )}
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
