/* istanbul ignore file */
import { AxiosResponse } from 'axios'
import {
  MpxItemResponse,
  MpxListResponse,
  MpxResponseError,
  OrganizationItem,
  ProgramEngagement,
  UserId,
} from '../types'
import { Field, ProgramConfig, ProgramStageType, ProgramTask } from '../types/Program'
import { GeneralResourceDetail, ResourceDetailsUpdate } from '../types/ResourceDetails'
import { generatePath } from '../utils'
import { DetailsResponse } from './types'
import { getMpAgent } from './utils'
import { queueRequest } from './utils/requestQueue'

const agent = getMpAgent()

/**
 * @todo: better typing
 * If you want to use a filter, you must _NOT_ pass in the programId
 */
export type ProgramEngagementsQueryParams = {
  programId?: Array<ProgramConfig['programId']>
  orgId?: OrganizationItem['orgInfo']['orgId']
  currentStepType?: ProgramStageType
  updatedAtGt?: Date
  updatedAtLte?: Date
  hasUncommittedFields?: boolean
  filters?: [
    {
      userId?: UserId | UserId[]
      programId?: string | string[]
    }
  ]
}

export type ProgramEngagementsDetailParams = {
  id: ProgramEngagement['id']
}

export type CreateProgramEngagementParams = Pick<ProgramEngagement, 'userId' | 'programId'> &
  Partial<Pick<ProgramEngagement, 'name' | 'space'>> &
  Partial<{ doesUpsert?: boolean }> & { fields?: string[] }

export type UpdateProgramEngagementParams = Pick<ProgramEngagement, 'id'> &
  Partial<Pick<ProgramEngagement, 'name'>> & {
    action: 'merge' | 'replace' | 'remove'
    fields: Array<Field['resourceId']>
  }

export type ProgramEngagementConfirmTaskParams = Pick<ProgramEngagement, 'id'> & {
  taskId: ProgramTask['id']
}

export type ProgramEngagementUnconfirmTasksParams = Pick<ProgramEngagement, 'id'> & {
  taskIds: ProgramTask['id'][]
}

export type EqipApplicationResultsParams = Pick<ProgramEngagement, 'id'> & {
  year?: number
  file?: any
  fileId?: string
  fileName?: string
  responseReceived?: boolean
  applicationApproved?: boolean
  totalContractValue?: number
}

export type ProgramEngagementLockDetailsError = {
  message: string
} & MpxResponseError

const UPDATE_PROGRAM_ENGAGEMENT_ERROR_TYPE = ['alreadyInWorkflow'] as const
export type UpdateProgramEngagementErrorType = typeof UPDATE_PROGRAM_ENGAGEMENT_ERROR_TYPE[number]

export type UpdateProgramEngagementError = {
  fields: Array<{
    id: Field['resourceId']
    message: string
  }>
  message: string
  errorType: UpdateProgramEngagementErrorType
} & MpxResponseError

const PATHS = {
  CONFIRM: '/program-engagements/:programEngagementId/confirm',
  DETAIL: '/program-engagements/:programEngagementId',
  FIELDS: '/program-engagements/:programEngagementId/fields',
  LIST: '/program-engagements',
  RESOURCE_DETAILS: '/program-engagements/:programEngagementId/details',
  UPLOAD_PAYMENT_FILE: '/program-engagements/:programEngagementId/upload/eqipApplicationResults',
  DOWNLOAD_PAYMENT_FILE: '/program-engagements/:programEngagementId/file/eqipApplicationResults',
}

export class ProgramEngagementsAPI {
  static list(params: ProgramEngagementsQueryParams) {
    return agent.get<MpxListResponse<ProgramEngagement>>(PATHS.LIST, {
      params: {
        ...params,
        programId: params.programId?.join(','),
        filters: !!params.filters?.length
          ? params.filters.map(filter => JSON.stringify(filter)).join(',')
          : undefined,
      },
    })
  }

  static detail({ id }: ProgramEngagementsDetailParams, options?: any) {
    return agent.get<ProgramEngagement>(
      generatePath(PATHS.DETAIL, { programEngagementId: id }),
      options
    )
  }

  static create(params: CreateProgramEngagementParams) {
    return agent.post<ProgramEngagement>(PATHS.LIST, params)
  }

  static update(params: UpdateProgramEngagementParams) {
    const { id, ...rest } = params
    return agent.put<ProgramEngagement>(
      generatePath(PATHS.DETAIL, { programEngagementId: id }),
      rest
    )
  }

  static delete({ id }: ProgramEngagementsDetailParams) {
    return agent.delete<MpxItemResponse<ProgramEngagement>>(
      generatePath(PATHS.DETAIL, { programEngagementId: id })
    )
  }

  static fields({ id }: ProgramEngagementsDetailParams) {
    return agent.get<MpxListResponse<Field>>(
      generatePath(PATHS.FIELDS, { programEngagementId: id })
    )
  }

  static confirmTask({ id, taskId }: ProgramEngagementConfirmTaskParams) {
    return agent.put<ProgramEngagement>(generatePath(PATHS.CONFIRM, { programEngagementId: id }), {
      taskId,
    })
  }

  static unconfirmTasks({ id, taskIds }: ProgramEngagementUnconfirmTasksParams) {
    return agent.delete<ProgramEngagement>(
      generatePath(PATHS.CONFIRM, { programEngagementId: id }),
      {
        params: { taskIds: taskIds.join(',') },
      }
    )
  }

  static getDetails<T extends GeneralResourceDetail = GeneralResourceDetail>({
    resourceId,
  }: {
    resourceId: string
  }) {
    return agent
      .get<DetailsResponse<T>>(
        generatePath(PATHS.RESOURCE_DETAILS, { programEngagementId: resourceId })
      )
      .then(response => response?.data)
  }

  static updateDetails({ resourceId, details }: ResourceDetailsUpdate) {
    return queueRequest<AxiosResponse<MpxItemResponse<GeneralResourceDetail>>>(() =>
      agent
        .patch<MpxItemResponse<GeneralResourceDetail>>(
          generatePath(PATHS.RESOURCE_DETAILS, { programEngagementId: resourceId }),
          {
            details,
          }
        )
        .then(response => response?.data)
    )
  }

  static uploadApplicationResults(params: EqipApplicationResultsParams) {
    const { id, ...rest } = params
    if (rest.file) {
      const formData = new FormData()
      rest.file && formData.append('file', rest.file)
      rest.fileId && formData.append('fileId', rest.fileId)
      rest.fileName && formData.append('fileName', rest.fileName)
      rest.year && formData.append('year', rest.year.toString())
      rest.responseReceived && formData.append('responseReceived', rest.responseReceived.toString())
      rest.applicationApproved &&
        formData.append('applicationApproved', rest.applicationApproved.toString())
      rest.totalContractValue &&
        formData.append('totalContractValue', rest.totalContractValue.toString())
      return agent.patch<any>(
        generatePath(PATHS.UPLOAD_PAYMENT_FILE, { programEngagementId: id }),
        formData
      )
    } else {
      return agent.patch<any>(
        generatePath(PATHS.UPLOAD_PAYMENT_FILE, { programEngagementId: id }),
        params
      )
    }
  }

  static getApplicationResultsUrl(params: { programEngagementId: string; year: number }) {
    const url = `${generatePath(PATHS.DOWNLOAD_PAYMENT_FILE, {
      programEngagementId: params.programEngagementId,
    })}?year=${params.year}`

    return url
  }
}
