/* eslint-disable */

export const PROGRAMS_QUERY_KEY = {
  ACTIVE_CARD: 'active_card',
  ALL_PROGRAM_CONTENT: 'all_program_content',
  COMMIT_COUNTS: 'my_enrollments',
  EQIP_RECOMMENDATIONS: 'eqip_recommendations',
  OPPORTUNITIES: 'opportunities',
  PROGRAM_CONFIGS: 'program_configs',
  PROGRAM_CONTENT: 'program_content',
  PROGRAM_ENGAGEMENTS: 'program_engagements',
  PROGRAM_GROWERS: 'program_growers',
  PROGRAM_STATS_ORG: 'program_stats_org',
  PROGRAM_STATS_USER: 'program_stats_user',
  PROGRAM_STATS: 'program_stats',
  PROGRAM_STATUS_AGGREGATE_FIELDCOUNT: 'program_status_fieldcount_aggregate',
  PROGRAM_STATUS_FIELDCOUNTS: 'program_status_fieldcounts',
  PROGRAMS: 'programs',
  USER_PROGRESS: 'userProgress',
  WORKFLOW_FIELDS: 'workflow_fields',
  WORKFLOW: 'workflow',
  WORKFLOWS: 'workflows',
}
