/* istanbul ignore file */
import {
  MpxResponseBaseSingle,
  PaginationRequest,
  PaginationResponse,
  ResourceDetail,
  UserId,
  getMpAgent,
} from '@cibo/core'

const PATHS = {
  CONTROL_GROUPS: '/termset/cpGroups',
  CONTROL_GROUP: '/termset/cpGroups/:id',
  QUERY: '/termset/cpGroups/query',
  BULK_CONFIRM: '/termset/cpGroups/bulkConfirm',

  // Clone existing plots onto this engagement
  // For users who were enrolled in the v1 program, this will bring over their existing control plots.
  // For new users, this will create new control plots.
  MIGRATE_OR_CREATE: '/termset/cpGroups/migrateOrCreateControlPlots',
}

export interface ControlGroup {
  agreementId: string
  controlPlotGroupId: string
  controlPlotType: 'biological'
  id: string
  isApproved: boolean
  committedAt?: string
  isCommitted?: boolean
  modelUnpaired?: boolean
  owner: number
  status: 'pending' | 'invalid' | 'valid'
  cpScenarios: ResourceDetail[]
  details: ResourceDetail[]
  user: { email: string; userId: UserId }
  name?: string
  createdDate?: string
  modifiedDate?: string
}

export type ControlGroupSearchQuery = {
  fullText?: string
  offset?: number
  limit?: number
  owner?: number
  controlPlotType?: string
  controlPlotGroupId?: string
  agreementId?: string
}

export type UpdateControlGroupRequest = Partial<Omit<ControlGroup, 'id'>> & { id: string }

export type BulkConfirmRequest = {
  agreementId: string
}

const agent = getMpAgent()

export class ControlGroupsAPI {
  static search(query?: PaginationRequest<ControlGroupSearchQuery>) {
    return agent
      .get<PaginationResponse<ControlGroup>>(PATHS.QUERY, { params: query })
      .then(response => response?.data)
  }

  static retrieve(id: string) {
    return agent
      .get<MpxResponseBaseSingle<ControlGroup>>({ template: PATHS.CONTROL_GROUP, params: { id } })
      .then(response => response?.data)
  }

  static update(request: UpdateControlGroupRequest) {
    return agent
      .patch<MpxResponseBaseSingle<ControlGroup>>(
        { template: PATHS.CONTROL_GROUP, params: { id: request.id } },
        {
          ...request,
          id: undefined,
        }
      )
      .then(response => response?.data)
  }

  static bulkConfirm(request: BulkConfirmRequest) {
    return agent.post(PATHS.BULK_CONFIRM, request).then(response => response?.data)
  }

  static migrateOrCreate(request: { engagementWfId: string; taskId: string }) {
    return agent.post(PATHS.MIGRATE_OR_CREATE, request).then(response => response?.data)
  }
}
